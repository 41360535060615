<template>
  <!-- https://blog.csdn.net/weixin_44001965/article/details/110225388 -->
  <div id="app">
    <div class="navBody" :style="style"></div>
    <div class="nav">
      <div class="nav_left" ref="logo">
        <img
          src="https://saas.lanou3g.com/orjImage/1/orjlogo_1.png?37"
          alt=""
        />
      </div>
      <div class="nav_right">
        <img
          src="https://i.jingyingba.com/photo/portrait/201512/20151220123658_T_121_2512.png"
          alt=""
        />
        <span>xxxx</span>
      </div>
    </div>
    <div class="body">
      <div class="slideLeft">
        <div class="left" :style="leftStyle">
          <el-row class="tac">
            <el-col :span="24">
              <el-menu
                :default-active="active"
                class="el-menu-vertical-demo"
                @select="selectAction"
                background-color="#eef3f6"
                text-color="#486382"
                active-text-color="#486382"
              >
                <el-menu-item index="1" v-if="role==1">
                  <div class="icon">
                    <i class="iconfont icon-jindu"></i>
                    <span slot="title">班级进度</span>
                  </div>
                </el-menu-item>
                <el-menu-item index="2" v-if="role==1">
                  <div class="icon">
                    <i class="iconfont icon-kaoshi"></i>
                    <span slot="title">每日一考</span>
                  </div>
                </el-menu-item>
                <el-menu-item index="3" v-if="role==1">
                  <div class="icon">
                    <i class="iconfont icon-zhou"></i>
                    <span slot="title">周考管理</span>
                  </div>
                </el-menu-item>
                <el-menu-item index="4" v-if="role==1">
                  <div class="icon">
                    <i class="iconfont icon-shixunchengji"></i>
                    <span slot="title">实训管理</span>
                  </div>
                </el-menu-item>
                <el-menu-item index="5" v-if="role==1">
                  <div class="icon">
                    <i class="iconfont icon-xiangmu_xiangmuguanli"></i>
                    <span slot="title">项目管理</span>
                  </div>
                </el-menu-item>
                <el-menu-item index="6" v-if="role==1">
                  <div class="icon">
                    <i class="iconfont icon-xiangmu_xiangmuguanli"></i>
                    <span slot="title">课后作业</span>
                  </div>
                </el-menu-item>
                <el-menu-item index="7" v-if="role==1">
                  <div class="icon">
                    <i class="iconfont icon-koubei1"></i>
                    <span slot="title">口碑管理</span>
                  </div>
                </el-menu-item>
                <el-menu-item index="8">
                  <div class="icon">
                    <i class="iconfont icon-resume-line" style="font-size:28px;margin-right:5px;margin-left:2px;"></i>
                    <span slot="title" style="font-size:14px;">学员简历</span>
                  </div>
                </el-menu-item>
                <el-menu-item index="9">
                  <div class="icon">
                    <i class="iconfont icon-kaoshi"></i>
                    <span slot="title">学员记录</span>
                  </div>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="right">
        <keep-alive :include="/(Live)$/">
          <router-view />
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
export default {
  data() {
    return {
      style: {
        h: 0,
      },
      leftStyle: {
        minHeight: 0,
      },
    };
  },
  computed: {
    active() {
      let index = this.$store.state.activeList.split("-")[0];
      return "" + index;
    },
    ...mapState(['role'])
  },
  mounted() {
    let img = new Image();
    img.src = "https://saas.lanou3g.com/orjImage/1/orjlogo_1.png?37";
    img.onload = () => {
      this.style = { height: this.$refs.logo.offsetHeight + 1 + "px" };
      let h = window.innerHeight - this.$refs.logo.offsetHeight - 1;
      this.leftStyle = {
        minHeight: h + "px",
      };
      window.onresize = () => {
        this.style = { height: this.$refs.logo.offsetHeight + 1 + "px" };
        let h = window.innerHeight - this.$refs.logo.offsetHeight - 1;
        this.leftStyle = {
          minHeight: h + "px",
        };
      };
    };
  },
  methods: {
    selectAction(index) {
      switch (Number(index)) {
        case 1:
          this.$router.replace("/home"); //进度查询页面
          break;
        case 2:
          this.$router.replace({ name: "TestCase" });
          break;
        case 3:
          this.$router.replace({ name: "TKCheck" });
          break;
        case 4:
          this.$router.replace({ name: "SXResultCheck" });
          break;
        case 5:
          this.$router.replace({ name: "XM" });
          break;
        case 6:
          this.$router.replace({ name: "KeCheng" });
          break;
        case 7:
          this.$router.replace({ name: "KouBei" });
          break;
        case 8:
          this.$router.replace({ name: "JianLi" });
          break;
        case 9:
          this.$router.replace({ name: "ScoreJiLu" });
          break;
        default:
          alert("页面不存在");
          break;
      }
    },
  },
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  font-size: 12px;
}
#app {
  width: 100%;
  min-width: 1000px;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #5e7997;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  min-width: 1000px;
}
.nav_right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  img {
    width: 36px;
    border-radius: 50%;
  }
  span {
    margin-left: 10px;
    padding-right: 15px;
  }
}
.nav_left {
  font-size: 0;
  width: 170px;
  img {
    width: 100%;
  }
}
.body {
  display: flex;
  justify-content: space-between;
}
.slideLeft {
  width: 170px;
  height: 10px;
  background-color: red;
}
.left {
  width: 170px;
  background-color: #eef3f6;
  position: fixed;
  left: 0;
  top: 60px;
  z-index: 100;
}
.right {
  width: calc(100% - 180px);
}
.el-menu-item.is-active {
  background-color: #fff !important;
}
.mark {
  height: 50px;
}
.icon i {
  margin-right: 10px;
  font-size: 20px;
}
.icon {
  display: flex;
  justify-content: center;
}
</style>
