// 导入公共IP
import { IP2 } from "../ipconfig.js";
//导入md5
import md5 from "js-md5";
//导入简历模块
import jianli from "./jianli.js" 
import pinglun from "./pinglun.js"
const BASEURL = "https://saas.lanou3g.com";
// md5签名算法
function md5Sign() {
    let date = new Date();
    date = new Date(date.getTime()-30000);
    let year = date.getFullYear();
    let month =
        date.getMonth() + 1 >= 10 ?
        date.getMonth() + 1 :
        "0" + (date.getMonth() + 1);
    let day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
    let hour = date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
    let sign = md5(`${year}${month}${day}${hour}`);
    return sign.toUpperCase();
}

// 该对象用来专门完成王路平请求配置
const datas = {
    //登录
    login(meta) {
        return {
            url: IP2 + "/exam/users/",
            method: "post",
            data: meta,
        };
    },
    // 首页数据
    home() {
        return {
            url: IP2 + "/exam/ky/",
            method: "post",
            data: {
                body: {
                    isAjaxDebug: false,
                    filePath: "teaching.html",
                    flag: "BanJiJinDu",
                    sign: md5Sign(),
                },
                target: BASEURL+"/do/deal",
            },
        };
    },
    //添加实训
    sxAdd(meta = {}) {
        return {
            url: IP2 + "/exam/sxMany/",
            method: "POST",
            data: meta.form,
        };
    },
    //实训删除
    sxDelete(meta) {
        return {
            url: IP2 + "/exam/sxOne/" + meta.id + "/",
            method: "DELETE",
        };
    },
    //添加实训报名
    bmAdd(meta) {
        console.log(meta);
        return {
            url: IP2 + "/exam/bmMany/",
            method: "POST",
            data: meta.form,
        };
    },
    // 删除实训报名
    bmDelete(meta) {
        return {
            url: IP2 + "/exam/bmOne/" + meta.id + "/",
            method: "delete",
        };
    },
    //查询实训信息
    sxCheck(meta = {}) {
        return {
            url: IP2 + "/exam/sxMany/",
            params: meta.params ? meta.params : {},
        };
    },
    //查询报名学生
    bmCheck(meta = {}) {
        return {
            url: IP2 + "/exam/bmMany/",
            params: meta.form ?
                {...meta.form, page: meta.page ? meta.page : 1 } :
                { page: meta.page ? meta.page : 1 },
        };
    },
    //更改报名
    updateBM(meta) {
        return {
            url: IP2 + "/exam/bmOne/" + meta.id + "/",
            method: "put",
            data: meta,
        };
    },
    //查询学生报名状态
    bmStateCheck(meta) {
        return {
            url: IP2 + "/exam/ky/",
            method: "post",
            data: {
                body: {
                    isAjaxDebug: false,
                    filePath: "student_behavior.html",
                    flag: "ShiXunJinBan",
                    sign: md5Sign(),
                    phones: meta.phones,
                },
                target: BASEURL+"/do/deal",
            },
        };
    },
    //每日一考模块
    jindu() {
        return {
            url: IP2 + "/exam/ky/",
            method: "post",
            data: {
                body: {
                    isAjaxDebug: false,
                    filePath: "teaching.html",
                    flag: "YiKaoJieDuan",
                    sign: md5Sign(),
                },
                target: BASEURL+"/do/deal",
            },
        };
    },
    jieduan(meta) {
        return {
            url: IP2 + "/exam/ky/",
            method: "post",
            data: {
                body: {
                    isAjaxDebug: false,
                    filePath: "teaching.html",
                    flag: "YiKaoKeCheng",
                    sign: md5Sign(),
                    classId: meta.class_Id,
                    courseId: meta.course_Id,
                },
                target: BASEURL+"/do/deal",
            },
        };
    },
    //班级每日一考成绩
    classScore(meta) {
        return {
            url: IP2 + "/exam/ky/",
            method: "post",
            data: {
                body: {
                    isAjaxDebug: false,
                    filePath: "teaching.html",
                    flag: "MeiRiYiKaoChengJi",
                    sign: md5Sign(),
                    classId: meta.class_Id,
                    examId: meta.knowexam_Id,
                },
                target: BASEURL+"/do/deal",
            },
        };
    },
    jdScore(meta) {
        // 学生阶段成绩查询
        return {
            url: IP2 + "/exam/ky/",
            method: "post",
            data: {
                body: {
                    isAjaxDebug: false,
                    filePath: "teaching.html",
                    flag: "MeiRiYiKaoChengJiByXueHao",
                    sign: md5Sign(),
                    xuehao: meta.xuehao,
                },
                target: BASEURL+"/do/deal",
            },
        };
    },
    //完成学生成绩添加
    addStuScoreById(meta){
        return {
            url:IP2+"/exam/addScoreById/",
            method:"post",
            data:meta
        }
    },
    //完成周考题库管理
    tkCheck(meta = {}) {
        return {
            url: IP2 + "/exam/many/",
            params: {
                page: meta.page ? meta.page : 1,
                xk: meta.xk ? meta.xk : "html",
            },
        };
    },
    //单题录入
    singleAdd(meta = {}) {
        return {
            url: IP2 + "/exam/many/",
            data: meta.ruleForm,
            method: "post",
        };
    },
    //完成课程数据查询
    kecheng(meta) {
        return {
            url: IP2 + "/exam/ky/",
            method: "post",
            data: {
                body: {
                    isAjaxDebug: false,
                    filePath: "teaching.html",
                    flag: "KeChengInfo",
                    sign: md5Sign(),
                    kcName: meta.kecheng,
                },
                target: BASEURL+"/do/deal",
            },
        };
    },
    kechengByClassName(meta) {
        return {
            url: IP2 + "/exam/ky/",
            method: "post",
            data: {
                body: {
                    isAjaxDebug: false,
                    filePath: "teaching.html",
                    flag: "KeChengInfo2",
                    sign: md5Sign(),
                    className: meta.className,
                },
                target: BASEURL+"/do/deal",
            },
        };
    },
    // 周考管理
    getClass(meta = {}) {
        console.log(meta);
        //获取周考班级
        let url = !meta.kind ? IP2 + "/exam/classes/?page=" + (meta.page ? meta.page : 1):(IP2 + "/exam/classes/?page=" + (meta.page ? meta.page : 1)+"&kind="+meta.kind);
        return {
            url:url,
        };
    },
    deleteClass(meta = {}) {
        //获取周考班级
        return {
            url: IP2 + "/exam/classOne/" + meta.id + "/",
            method: "delete",
        };
    },
    deleteScore(meta) {
        return {
            url: IP2 + "/exam/score/" + meta.id + "/",
            method: "delete",
            data: meta,
        };
    },

    //周考成绩管理
    getTms(meta){
        let page = meta.page ? meta.page:1;
        return {
            url: IP2 + "/exam/tms/?page="+page
        };
    },
    addZKjilu(meta) {
        return {
            url: IP2 + "/exam/tms/",
            method: "post",
            data: meta,
        };
    },
    addBanji(meta) {
        return {
            url: IP2 + "/exam/classes/",
            method: "post",
            data: meta,
        };
    },
    //根据班级获取学生信息
    getStus(meta) {
        return {
            url: IP2 + "/exam/ky/",
            method: "post",
            data: {
                body: {
                    isAjaxDebug: false,
                    filePath: "student_behavior.html",
                    flag: "XueShengInfo",
                    sign: md5Sign(),
                    className: meta.className,
                },
                target: BASEURL+"/do/deal",
            },
        };
    },
    //添加学生信息
    addStu(meta) {
        return {
            url: IP2 + "/exam/stus/",
            method: "post",
            data: meta,
        };
    },
    //添加考试成绩
    addScores(meta) {
        return {
            url: IP2 + "/exam/zks/",
            method: "post",
            data: meta,
        };
    },
    getScores(meta) {
        return {
            url: IP2 + "/exam/tms/?zhoukaoName=" + meta.zhoukaoName,
        };
    },
    updateScore(meta) {
        return {
            url: IP2 + "/exam/zk/" + meta.t_id + "/",
            method: "put",
            data: meta,
        };
    },
    deleteScoreById(meta){
        // 删除学生成绩
        return {
            url: IP2 + "/exam/zk/" + meta.t_id + "/",
            method: "delete"
        };
    },
    getClasses() {
        return {
            url: IP2 + "/exam/classes/",
        };
    },

    //课程表
    getCourses() {
        return {
            url: IP2 + "/exam/courseM/",
        };
    },

    /****************************项目管理********************/
    getXM(meta) {
        return {
            url: IP2 + "/exam/xiangmuM/",
            data: meta ? meta : {},
        };
    },
    getXMC(meta){
        return {
            url:IP2 + "/exam/xmCheckO/"+meta.id+"/?kind="+meta.kind
        }
    },
    addClassName(meta) {
        return {
            url: IP2 + "/exam/bjxiangmuM/",
            method: "POST",
            data: { className: meta.className },
        };
    },
    addXMCheck(meta) {
        return {
            url: IP2 + "/exam/xmCheckM/",
            method: "POST",
            data: {...meta },
        };
    },
    getChecks(meta) {
        return {
            url: IP2 + "/exam/bjxiangmuM/?classes="+JSON.stringify(meta.classNames),
        };
    },
    deleteCheck(meta) {
        return {
            url: IP2 + "/exam/xmCheckO/" + meta.id + "/",
            method: "DELETE",
        };
    },
    deleteBanJi(meta) {
        return {
            url: IP2 + "/exam/bjXM/" + meta.id + "/",
            method: "DELETE",
        };
    },
    getBanJi(meta){
        return {
            url: IP2 + "/exam/bjXM/" + meta.id + "/",
        };
    },
    addBJCheck(meta) {
        return {
            url: IP2 + "/exam/bjCheckM/",
            method: "POST",
            data: meta,
        };
    },
    updateBjCheck(meta) {
        return {
            url: IP2 + "/exam/bjCheckO/" + meta.id + "/",
            method: "PUT",
            data: meta,
        };
    },
    getStusByClassName(meta) {
        return {
            url: IP2 + "/exam/classes/?className=" + meta.className,
        };
    },
    addStudents(meta) {
        return {
            url: IP2 + "/exam/bjStudents/",
            method: "post",
            data: meta,
        };
    },
    getXMChecks(meta) {
        return {
            url: IP2 + "/exam/xmCheckO/" + meta.id + "/",
        };
    },
    getStuByID(meta) {
        return {
            url: IP2 + "/exam/bjStudent/" + meta.id + "/",
        };
    },
    updateStuParse(meta) {
        return {
            url: IP2 + "/exam/bjStudent/" + meta.id + "/",
            method: "PUT",
            data: meta,
        };
    },

    //班级课后作业管理
    getZuoYeByStu(meta){
        // 获取班级某一个阶段的作业
        return {
            url:IP2+"/exam/zy_check/?jieduan_name="+meta.jieduan_name+"&className="+meta.className
        }
    },
    addZuoYeCheck(meta) {
        return {
            url: IP2 + "/exam/zycM/",
            method: "POST",
            data: meta,
        };
    },
    getZuoYe(meta) {
        return {
            url: IP2 + "/exam/zycM/?kind="+meta.kind+"&classes="+JSON.stringify(meta.classNames),
        };
    },
    getZuoYeOne(meta){
        //获取某一个作业
        return {
            url: IP2 + "/exam/zycO/" + meta.id + "/",
        } 
    },
    updateZuoYeOne(meta){
        //更新作业
        return {
            url:IP2+"/exam/zycO/"+meta.id+"/",
            method:"PUT",
            data:meta
        } 
    },
    deleteZuoYe(meta) {
        return {
            url: IP2 + "/exam/zycO/" + meta.id + "/",
            method: "DELETE",
        };
    },
    addZuoYeJiLu(meta) {
        return {
            url: IP2 + "/exam/zyjlM/",
            method: "POST",
            data: meta,
        };
    },
    updateZuoYeJiLu(meta) {
        return {
            url: IP2 + "/exam/zyjlO/" + meta.id + "/",
            method: "PUT",
            data: meta,
        };
    },
    //口碑数据
    getKouBei(meta = { kind: 1 }) {
        meta.kind = meta.kind ? meta.kind:0;
        let url = IP2 + "/exam/koubeiM/?kind=" + meta.kind;
        if (meta.date) {
            url = `${IP2}/exam/koubeiM/?kind=${meta.kind}&date=${meta.date}`;
        }
        return {
            url,
        };
    },
    updateKouBei(meta) {
        // console.log(meta);
        return {
            url: IP2 + "/exam/koubeiO/" + meta.id + "/",
            method: "PUT",
            data: meta,
        };
    },
    koubeiFilter(meta) {
        meta.year = meta.year ? meta.year : (new Date()).getFullYear();
        return {
            url: IP2 + `/exam/koubeiF/?kind=${meta.kind}&info=${meta.info}&year=${meta.year}`,
        };
    },
    getKouBeiState(meta) {
        return {
            url: IP2 + "/exam/ky/",
            method: "post",
            data: {
                body: {
                    isAjaxDebug: false,
                    filePath: "student_behavior.html",
                    flag: "KouBeiXinXi",
                    sign: md5Sign(),
                    telOrIm: meta.telOrLM,
                },
                target: BASEURL+"/do/deal",
            },
        };
    },
    //根据手机号查询成绩
    getHistoryScores(meta){
        return {
            url: IP2 + meta.url+"?tel="+meta.tel,
        };
    },
    //获取所有的班级
    getClassInSchool() {
        return {
            url: IP2 + "/exam/ky/",
            method: "post",
            data: {
                body: {
                    isAjaxDebug: false,
                    filePath: "student_behavior.html",
                    flag: "ClassList",
                    sign: md5Sign(),
                },
                target: BASEURL+"/do/deal",
            },
        };
    },
    //留级
    addLiuJiClass(meta){
        return {
            url:IP2+"/exam/liujiCM/",
            method:"POST",
            data:meta
        }
    }, 
    addLiuJiInfo(meta){
        return {
            url:IP2+"/exam/liujiIM/",
            method:"POST",
            data:meta
        }
    },
    getLiuJiClass(){
        return {
            url:IP2+"/exam/liujiCM/"
        }
    },
    getLiuJiInfo(meta){
        return {
            url:IP2+"/exam/liujiIO/1/?className="+meta.className+"&kind="+meta.kind
        } 
    },
    ...jianli,
    ...pinglun
};

export default datas;